/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import "./src/styles/normalize.css"
import "./src/styles/skeleton.css"
import "./src/styles/fontello.css"
import "./src/styles/global.css"

import React from "react"
//import { ApolloProvider } from "react-apollo"
import { ApolloProvider } from "@apollo/react-hooks"

import { client } from "./src/config/client"

export const wrapRootElement = ({ element }) => (
  <ApolloProvider client={client}>{element}</ApolloProvider>
)
